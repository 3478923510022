import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme:Theme) => ({
  defaultButton: {
    marginLeft:theme.spacing(2),
    '& button':{
      border: '1px solid #1976D2',
      padding:'4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize:'14px',
      fontWeight: '400',
      minWidth:'140px',
      textTransform:'capitalize',
      color:'#1976D2'
    }
  },
  primaryButton:{
    '& button':{
      background: '#1976D2',
      color:'#fff'
    }
  },
  primaryButtonWithIcon:{
    '& button':{
      color:'#fff',
      background:'#63A4FF ',
      boxShadow:'none',
      '& .MuiButton-startIcon':{
        backgroundColor: '#fff',
        borderRadius: '2px',
        fontWeight:'400',
        color: '#63A4FF ',
        marginRight: '16px',
        width: '16px',
        height: '16px',
        '& svg':{
          fontSize:'16px',
        }       
      },
      '&:hover':{
        color: '#fff',
        boxShadow:'none',
        background:'#63A4FF',
      },
      '& button:disabled': {
        background: '#afafaf',
        border: 'none',
      },
    }
  }
}));