import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { CountryModel } from '@uplink/shared';
import { SettingBaseModel } from './SettingBase.model';

@modelProtection
export class AppliedDistinctModel extends CoreModel implements ISelectOption {
  userId: string = '';
  appliedDisinsectionCountryId: number = 0;
  appliedDisinsectionTypeId: number = 0;
  appliedDisinsectionChemicalId: number = 0;
  disinsectionType: SettingBaseModel = new SettingBaseModel();
  disinsectionChemical: SettingBaseModel = new SettingBaseModel();
  countryReference: CountryModel = new CountryModel();

  constructor(data?: Partial<AppliedDistinctModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: AppliedDistinctModel): AppliedDistinctModel {
    if (!apiData) {
      return new AppliedDistinctModel();
    }
    const data: Partial<AppliedDistinctModel> = {
      ...apiData,
      countryReference: CountryModel.deserialize(apiData.countryReference),
    };
    return new AppliedDistinctModel(data);
  }

  static deserializeList(apiDataList: AppliedDistinctModel[]): AppliedDistinctModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => AppliedDistinctModel.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      id: this.id || 0,
      userId: this.userId,
    };
  }

  public get label(): string {
    if (this.countryReference?.label) return this.countryReference.label;
    if (this.disinsectionType.label) return this.disinsectionType.label;
    if (this.disinsectionChemical.label) return this.disinsectionChemical.label;
    return '';
  }

  public get value(): string | number {
    if (this.countryReference?.value) return this.countryReference.value;
    if (this.disinsectionType?.value) return this.disinsectionType.value;
    if (this.disinsectionChemical?.value) return this.disinsectionChemical.value;
    return '';
  }
}
