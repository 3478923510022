import { auditFields } from '@uplink/shared';
import { regex } from '@wings-shared/core';

export const fields = {
  ...auditFields,
  id: {
    label: 'Event Id',
  },
  refDataId:{
    label:'Ref Data Id',
  },
  name: {
    label: 'Name*',
    rules: 'required|max:255'
  },
  description: {
    label: 'Description',
    rules: 'string|max:1000'
  },
  eventTypeName: {
    label: 'Type',
  },
  eventCategory:{
    label: 'Category'
  },
  specialConsiderationName:{
    label: 'Special Consideration',
  },
  worldEventEffectType:{
    label: 'What does this affect?*',
    rules: 'required'
  },
  url:{
    label: 'URL',
    // rules: `regex:${regex.url}`
    rules:'string|between:3,500|regex:/^[^\\s]+\\.[^\\s]+$/'
  },
  startDate: {
    label: 'Start Date*',
    rules: 'required'
  },
  endDate: {
    label: 'End Date*',
    rules: 'required'
  },
  beginPlanning: {
    label: 'Is there a date operators should begin planning for this event?',
    rules: 'numeric|between:0,365'
  },
}