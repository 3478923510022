import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { RootDataStore } from '@uplink-shared/layout';
import { VendorLocationAddressModel } from './VendorLocationAddress.model';
import { Airports } from './Airports.model';
import { SettingBaseModel } from './SettingBase.model';
import { WorldEventSpecialConsiderationModel } from './WorldEventSpecialConsideration.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';

@modelProtection
export class WorldEvents extends CoreModel implements ISelectOption {
  userId: string = '';
  id: number = 0;
  name: string = '';
  refDataId: number = 0;
  description: string = '';
  eventTypeId: number = 0;
  eventTypeName: SettingBaseModel = new SettingBaseModel();
  eventCategoryId: number = 0;
  eventCategoryName: string = '';
  eventCategory: SettingBaseModel = new SettingBaseModel();
  url: string = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  beginPlanning: number = 0;
  worldEventEffectType: SettingBaseModel = new SettingBaseModel();
  specialConsiderationName: WorldEventSpecialConsiderationModel[] = [];
  countryReferenceId: number = 0;
  stateReferenceId: number = 0;
  cityReferenceId: number = 0;
  airportReferenceId: number = 0;
  airportReference: Airports = new Airports();
  cityReference: CityModel = new CityModel();
  stateReference: StateModel = new StateModel();
  countryReference: CountryModel = new CountryModel();
  statusId: number = 0;
  vendorLocationId: number = 0;

  constructor(data?: Partial<WorldEvents>) {
    super(data);
    Object.assign(this, data);
  }

  public static handlerEventOptions() {
    return SettingBaseModel.deserializeList([
      { name: 'Airport', id: 1 },
      { name: 'City', id: 2 },
      { name: 'Department', id: 3 },
      { name: 'Country', id: 4 },
    ]);
  }

  public serialize(statusId: number): WorldEvents {
    const serializedSpecialConsiderations =
      this.specialConsiderationName != '' ? this.specialConsiderationName?.map(item => item.serialize()) || [] : null;


    return {
      userId: this.userId || '',
      id: this.id || 0,
      name: this.name || '',
      refDataId: this.refDataId || 0,
      description: this.description || '',
      eventTypeId: this.eventTypeId || null,
      eventTypeName: this.eventTypeName?.name || '',
      eventCategoryId: this.eventCategory?.id || null,
      eventCategoryName: this.eventCategory?.name || '',
      url: this.url || '',
      startDate: this.startDate || new Date(),
      endDate: this.endDate || new Date(),
      beginPlanning: this.beginPlanning || null,
      worldEventEffectType:
        this.worldEventEffectType.name?.toLocaleLowerCase() === 'department'
          ? 'state'
          : this.worldEventEffectType.name?.toLocaleLowerCase() || '',
      appliedWorldEventSpecialConsiderationReferenceRequest: serializedSpecialConsiderations,
      countryId: this.countryReferenceId || null,
      stateId: this.stateReferenceId || null,
      cityId: this.cityReferenceId || null,
      airportId: this.airportReferenceId || null,
      statusId: statusId || this.statusId || null,
      vendorLocationId: RootDataStore?.locationData?.locationId,
    };
  }

  static deserialize(apiData: WorldEvents): WorldEvents {
    if (!apiData) {
      return new WorldEvents();
    }
    const data: Partial<WorldEvents> = {
      ...apiData,
      id: apiData?.id,
      refDataId: apiData?.refDataId,
      name: apiData.name,
      description: apiData.description,
      eventTypeName: SettingBaseModel.deserialize({
        id: apiData.eventTypeId,
        name: apiData.eventTypeName,
      }),

      eventCategory: SettingBaseModel.deserialize({
        id: apiData.eventCategoryId || apiData.worldEventCategoryId,
        name: apiData.eventCategoryName,
      }),
      worldEventEffectType:
        apiData?.worldEventEffectType === 'state'
          ? SettingBaseModel.deserialize({ name: 'Department', id: 3 })
          : SettingBaseModel.deserialize(
            WorldEvents.handlerEventOptions().find(
              item =>
                  item.name?.toLocaleLowerCase() === apiData?.worldEventEffectType?.toString()?.toLocaleLowerCase()
            )
          ),
      url: apiData.url,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      specialConsiderationName: WorldEventSpecialConsiderationModel.deserializeList(
        apiData?.appliedWorldEventSpecialConsiderationReferenceResponse
      ),
    };
    return new WorldEvents(data);
  }

  static deserializeList(apiDataList: WorldEvents[]): WorldEvents[] {
    return apiDataList ? apiDataList.map(apiData => WorldEvents.deserialize(apiData)) : [];
  }

  static getWorldEventEntityId(
    isHanlderBulletin: boolean,
    isAirportBulletin: boolean,
    isCountryBulletin: boolean,
    bulletinLevelId: number,
    locationAddress: VendorLocationAddressModel
  ) {
    const locationData = RootDataStore.locationData;
    if (isHanlderBulletin) {
      return locationData.locationId;
    }

    if (isAirportBulletin && bulletinLevelId == 3) {
      return locationAddress?.cityReference?.id;
    }

    if (isAirportBulletin && bulletinLevelId == 1) {
      return locationData.airportReferenceId;
    }
    if (isCountryBulletin && bulletinLevelId == 4) {
      return locationAddress?.countryReference?.countryId;
    }
    if (isCountryBulletin && bulletinLevelId == 5) {
      return locationAddress?.stateReference?.stateId;
    }
  }

  public get label(): string {
    return this.name || '';
  }

  public get value(): string | number {
    return this.id;
  }
}
